<template>
  <div>
    <!-- <div class="title-lot-send-mail">{{ lot }}</div> -->
    <b-row class="row m-0">
      <b-col col xs="5" sm="11" md="5" class="blok-mail-obligee-type">
        <step-content
          :label="'Organisme'"
          :loader="loaderAcheteurMailObligee"
          :error="errorAcheteurMailObligee"
          :dataToUse="MailDataAcheteurObligee"
      /></b-col>
      <b-col col xs="5" sm="11" md="5" class="blok-mail-obligee-type"
        ><step-content
          :label="'Installateur'"
          :loader="loaderVendeurMailObligee"
          :error="errorVendeurMailObligee"
          :dataToUse="MailDataVendeurObligee"/></b-col
    ></b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import stepContent from './stepContent.vue';
export default {
  props: { lot: { require: true } },
  components: { stepContent },
  computed: {
    ...mapGetters([
      'loaderAcheteurMailObligee',
      'loaderVendeurMailObligee',
      'errorAcheteurMailObligee',
      'errorVendeurMailObligee',
      'MailDataVendeurObligee',
      'MailDataAcheteurObligee'
    ])
  }
};
</script>

<style scoped lang="scss">
.title-lot-send-mail {
  color: #4d4bac;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}
.blok-mail-obligee-type {
  background-color: #f6f5fb;
  border: 1px solid #e0ddf0;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 20px;
  margin: 5px 4%;
}
</style>
